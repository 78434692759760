@import url("https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,300;0,400;0,600;1,300&display=swap");

body {
  margin: 0;
  font-family: "Jost", "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body,
html,
#root {
  height: 100%;
}
