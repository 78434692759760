.Flow {
  flex-grow: 1;
  font-size: 12px;
}

/* CSS */
.btn-green {
  appearance: none;
  margin: 0 4px;
  background-color: #9381ff;
  border: 1px solid rgba(27, 31, 35, 0.15);
  border-radius: 6px;
  box-shadow: rgba(27, 31, 35, 0.1) 0 1px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system, system-ui, "Segoe UI", Helvetica, Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  padding: 6px 16px;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  white-space: nowrap;
}

.btn-green:focus:not(:focus-visible):not(.focus-visible) {
  box-shadow: none;
  outline: none;
}

.btn-green:hover {
  background-color: #8171db;
}

.btn-green:focus {
  box-shadow: rgba(46, 164, 79, 0.4) 0 0 0 3px;
  outline: none;
}

.btn-green:disabled {
  background-color: #94d3a2;
  border-color: rgba(27, 31, 35, 0.1);
  color: rgba(255, 255, 255, 0.8);
  cursor: default;
}

.button-3:active {
  background-color: #298e46;
  box-shadow: rgba(20, 70, 32, 0.2) 0 1px 0 inset;
}

.react-flow__node-mid,
.react-flow__node-top,
.react-flow__node-bottom {
  border: 1px solid rgba(0, 0, 0, 0);
  padding: 8px;

  /* width: 200px; */
  border-radius: 4px;
}

.react-flow__node-top {
  background: #ffeedd;
}

.react-flow__node-mid {
  background: #ffe6cd;
}

.react-flow__node-bottom {
  background: #ffd8be;
}

.react-flow__node-mid:has(div.selected),
.react-flow__node-top:has(div.selected),
.react-flow__node-bottom:has(div.selected) {
  border: 1px solid black;
}

.react-flow__node-mid > div,
.react-flow__node-top > div,
.react-flow__node-bottom > div {
  width: 100%;
  height: 100%;
}

/* Label */
.react-flow__node-mid input[type="text"],
.react-flow__node-top input[type="text"],
.react-flow__node-bottom input[type="text"] {
  font-weight: bold;
}

textarea {
  resize: none;
  font-size: 12px;
  min-width: 350px;
  font-family: "Jost", "Open Sans", sans-serif;
  min-height: 40px;
}

/* CSS */
.button-waitlist {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  letter-spacing: 2px;
  text-decoration: none;
  text-transform: uppercase;
  color: #000;
  cursor: pointer;
  border: 3px solid;
  padding: 0.25em 0.5em;
  box-shadow: 1px 1px 0px 0px, 2px 2px 0px 0px, 3px 3px 0px 0px, 4px 4px 0px 0px,
    5px 5px 0px 0px;
  position: relative;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-waitlist:hover {
  box-shadow: 2.5px;
  top: 2.5px;
  left: 2.5px;
}

.button-waitlist:active {
  box-shadow: 0;
  top: 5px;
  left: 5px;
}

@media (min-width: 768px) {
  .button-waitlist {
    padding: 0.25em 0.75em;
  }
}

.btnGroup {
  display: flex;
  justify-content: space-between;
}

.choiceTable {
  text-align: left;
  border: 1px solid #9381FF;
  cursor: pointer;
}

tr {
  border: 1px solid black;
}
