.App {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.App-header {
  height: 40px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eee;
  padding: 0 1rem;
  flex: 0 1 auto;
}

.instruction-entry {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  padding: 1rem;
}

@media screen and (min-width: 768px) {
  .instruction-entry {
    flex-direction: row;
  }
}

/* .App-header span {

} */
